export default [{
  path: '/privacy-policy',
  name: 'privacy-policy',
  component: () => import('@/views/docs/DocViewer.vue'),
  meta: {
    allowAnonymous: true,
    layout: 'full',
  },
  props: { documentID: 'privacy_policy' },
},

{
  path: '/pdpa',
  name: 'data-protection-policy',
  component: () => import('@/views/docs/DataProtection.vue'),
  meta: {
    allowAnonymous: true,
    layout: 'full',
  },
},

{
  path: '/terms',
  name: 'Terms and conditions',
  component: () => import('@/views/docs/Terms.vue'),
  meta: {
    allowAnonymous: true,
    layout: 'full',
  },
},
{
  path: '/loa',
  name: "E2i's Letter Of Agreement",
  component: () => import('@/views/docs/DocViewer.vue'),
  meta: {
    allowAnonymous: true,
    layout: 'full',
  },
  props: { documentID: 'loa' },
},
{
  path: '/user-guide',
  name: 'user-guide',
  component: () => import('@/views/guide/UserGuide.vue'),
  group: ['agency', 'employer'],
  meta: {
    breadcrumb: [{
      title: 'Home',
      url: '/',
    },
    {
      title: 'User Guide',
      active: true,
    },
    ],
    pageTitle: 'User Guide',
    rule: 'editor',
    // group: ["superuser", "applicant", "employee", "elevated"],
    allowAnonymous: false,
  },
},
]
